import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import Pagination from '../../components/Pagination/Pagination';
import { getUsers } from '../../services/UsersService';
import SearchUser from './SearchUser';
import UserRow from './UserRow';
import UserModal from './UserModal/UserModal';

function Message() {

    const defaultLocation = useLocation();

    const [users, setUsers] = useState([]);

    const [sendUser, setSendUser] = useState({});

    const [count, setCount] = useState(0);

    const [search, setSearch] = useState('');

    const [page, setPage] = useState(getPage());


    const [notification, setNotification] = useState({ type: '', text: '' });

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        setPage(getPage(defaultLocation));
    }, [defaultLocation])

    useEffect(() => {
        getUsers(search, page || 1)
            .then(result => {
                setUsers(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page, search])

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function onSendClick(event) {
        const id = event.target.id.replace('edit', '');
        setSendUser(users.find(u => u.id == id));
    }

    function onModalSubmit(event) {
        window.location.reload();
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Send Message</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <SearchUser placeholder={search} onChange={onSearchChange} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Name</th>
                                <th className="border-gray-200">Email</th>
                                <th className="border-gray-200">Limit</th>
                                <th className="border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users && users.length
                                    ? users.map(user => (<UserRow key={user.id} data={user} onSendClick={onSendClick} />))
                                    : <></>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <UserModal data={sendUser} onSubmit={onModalSubmit} />
            <Toast text={notification.text} type={notification.type} />
        </>
    )
}


export default Message;