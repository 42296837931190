import React, { useRef, useState, useEffect } from 'react';
import { sendInfo } from '../../../services/UsersService';

/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {

    const DEFAULT_USER = {
        name: '',
        email: '',
        password: '',
        limitId: 0,
        telegramChat: '',
        phone: '',
        isActive: false
    }

    const [error, setError] = useState('');

    const [user, setUser] = useState(DEFAULT_USER);

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        sendInfo(user.id, user)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }


    function onInputChange(event) {
        setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        setUser(props.data);
    }, [props.data.id])

    useEffect(() => {
        const modal = document.getElementById('modalUser');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setUser({ ...DEFAULT_USER });
        })
    }, [])

    return (
        <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}User</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="info">Send Message:</label>
                                        <textarea type="text" id="info" className="form-control" onChange={onInputChange}  placeholder="send Message"  rows="4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserModal;
