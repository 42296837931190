// Message.js
import React, { useState } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { sendInfoAll } from '../../services/UsersService';
import SearchUser from './SearchUser';


function Message() {
  
    const [search, setSearch] = useState('');

    const [notification, setNotification] = useState({ type: '', text: '' });
    const [message, setMessage] = useState('');



    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function handleMessageChange(event) {
        setMessage(event.target.value);
    }

    function handleSendMessage() {
        if (message.trim()) {
            sendInfoAll(message)
                .then(() => {
                    setNotification({ type: 'success', text: 'Message sent to all users successfully!' });
                    setMessage(''); // Clear the message field after sending
                })
                .catch(err => {
                    setNotification({ type: 'error', text: err.message });
                });
        } else {
            setNotification({ type: 'error', text: 'Please enter a message before sending.' });
        }
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Send Message</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <SearchUser placeholder={search} onChange={onSearchChange} />
                        </div>
                    </div>
                </div>

                {/* Form to send message */}
                <div className="card card-body border-0 shadow mb-4">
                    <h4 className="mb-3">Send a Message to All Users</h4>
                    <textarea
                        className="form-control"
                        rows="4"
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Type your message here"
                    />
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSendMessage}
                    >
                        Send Message
                    </button>
                </div>
                <Footer />
            </main>

            <Toast text={notification.text} type={notification.type} />
        </>
    );
}

export default Message;
